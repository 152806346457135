import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import { GooglePlayButton } from "../components/GooglePlayButton"
import { SectionTransparent, Section } from "../components/Section"

const Title = styled.h1`
  color: blue;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Forside" />
    <SectionTransparent
      left
      title="Telefonen blir låst automatisk"
      text="Kjør oppdager Bluetooth-enheten i bilen din, og låser telefonen automatisk når du begynner å kjøre. Den sørger for å kun låse telefonen i din bil. Og om du er passasjer kan du enkelt hindre at telefonen låses."
      phoneSrc="3_bluetooth.png"
    />
    <Section
      title="Telefonen er låst mens du kjører"
      text="Mens Kjør er koblet til bilens Bluetooth, kan du bare bruke telefonen til å ringe nødnumre. Du kan alltids bruke bilens handsfree-funksjoner til å ta imot anrop eller styre musikken."
      phoneSrc="2_locked.png"
    />
    <SectionTransparent
      left
      title="Turene dine blir lagret"
      text="Telefonen din låses opp automatisk når Kjør kobler fra bilens Bluetooth. Klokkeslettene loggføres, slik at du får oversikt over når telefonen har vært låst."
      phoneSrc="4_log.png"
    />
    <Section
      title="Last ned og ha en trygg tur!"
      text="Det er raskt og enkelt å bruke Kjør for å bli en tryggere sjåfør. Alt som skal til er fem minutter, en Android-telefon og en bil med Bluetooth (både innebygd og ettermontert adapter støttes)."
      phoneSrc="5_download.png"
    >
      <GooglePlayButton />
    </Section>
  </Layout>
)

export default IndexPage
